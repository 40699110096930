import { getUrlSync, IActionContext } from '@msdyn365-commerce/core';
import React from 'react';
import { Button } from '@msdyn365-commerce-modules/utilities';

export const getSubscriptionDetailPageUrl = (actionContext: IActionContext, SubscriptionId: string, LineId: number): string => {
    // TODO: Get 'subscription-details' from route configuration or global configuration maintained in Sitebuilder
    const subscriptionsUrl = getUrlSync('subscription-details', actionContext) || '';
    const separator = subscriptionsUrl.includes('?') ? '&' : '?';

    return `${subscriptionsUrl}${separator}subBillBillingScheduleNumber=${SubscriptionId}&subBillLineNum=${LineId}`;
};

interface INosSubscriptionDetailButtonProps {
    actionContext: IActionContext;
    SubscriptionId: string;
    LineId: number;
    buttonName: string;
}

const NosSubscriptionDetailButton: React.FC<INosSubscriptionDetailButtonProps> = ({
    actionContext,
    SubscriptionId,
    LineId,
    buttonName
}) => {
    return (
        <React.Fragment>
            <Button href={getSubscriptionDetailPageUrl(actionContext, SubscriptionId, LineId)}>{buttonName}</Button>
        </React.Fragment>
    );
};

export default NosSubscriptionDetailButton;
