import { SubscriptionStatus } from '../../nos-subscription-details/enums/subscription-status-enum';
import { INosSubscriptionListResources } from '../nos-subscription-list.props.autogenerated';

export const getSubscriptionStatus = (resources: INosSubscriptionListResources, status?: number) => {
    switch (status) {
        case SubscriptionStatus.ACTIVE:
            return resources.active;
        case SubscriptionStatus.ARCHIVED:
            return resources.archived;
        case SubscriptionStatus.LASTBILLING:
            return resources.lastBilling;
        case SubscriptionStatus.ONHOLD:
            return resources.onHold;
        case SubscriptionStatus.TERMINATED:
            return resources.terminated;
        default:
            return '';
    }
};

import { ICoreContext, IDateFormatOptions } from '@msdyn365-commerce/core';

/**
 * Formats date.
 * @param context - Core context.
 * @param date - Date to display.
 * @returns String value.
 */
export const formatDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }

    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return context.cultureFormatter.formatDate(date, dateOptions);
};
